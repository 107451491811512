import React from "react";
import { connect } from "react-redux";

import Content from "../Content";
import FramedTitle from "../FramedTitle";
import Hero from "../Hero";
import Markdown from "../Markdown";
import Notice from "../Notice";

import hero from "../Home/images/pub.webp";
import heroNarrow from "../Home/images/pub-narrow.webp";

const VenueHome = ({ location, notices }) => {
  const title = location.charAt(0).toUpperCase() + location.slice(1);
  const notice = notices[location];

  return (
    <>
      <Hero src={hero} narrowSrc={heroNarrow} />
      <Content>
        <FramedTitle text={title} />
          <Notice title="Hi there!" frameStyle="brown">
            <Markdown>{notice}</Markdown>
          </Notice>
      </Content>
    </>
  );
};

const mapStateToProps = ({ contents: { bexleyHome, elthamHome } }) => ({
  notices: {
    bexley: bexleyHome,
    eltham: elthamHome,
  },
});

export default connect(mapStateToProps)(VenueHome);
