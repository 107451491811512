import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { VenueContext } from "../../VenueContext";
import BeerList from "../BeerList";
import Tile from "../Tile";

import * as styles from "./styles.module.scss";

const BeerLists = ({ group }) => {
  const venue = useContext(VenueContext);
  const menus = useSelector((state) =>
    state.menus.filter(
      (menu) => menu.groups.indexOf(group) > -1 && menu.venue === venue,
    ),
  );

  const [currentMenu, setCurrentMenu] = useState(menus[0]);

  useEffect(() => {
    menus && menus.length && setCurrentMenu(menus[0]);
  }, []);

  return (
    <>
      {menus.length > 1 && (
        <div className={styles.buttonsContainer}>
          <div>
            {menus.map((menu) => (
              <Tile
                onClick={() => setCurrentMenu(menu)}
                text={menu.name}
                isActive={
                  currentMenu && currentMenu.untappdId === menu.untappdId
                }
                key={menu.untappdId}
              />
            ))}
          </div>
        </div>
      )}
      <BeerList menu={currentMenu} />
    </>
  );
};

export default BeerLists;
