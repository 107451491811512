import React, { useContext } from "react";
import { useSelector } from "react-redux";

import { VenueContext } from "../../VenueContext";
import Content from "../Content";
import EventSummary from "../EventSummary";
import FramedTitle from "../FramedTitle";
import Tile from "../Tile";

import * as styles from "./styles.module.scss";

export const WhatsOn = () => {
  const venue = useContext(VenueContext);
  const past = useSelector((state) =>
    state.events.past.filter((event) => event.venue === venue),
  );
  const upcoming = useSelector((state) =>
    state.events.upcoming.filter((event) => event.venue === venue),
  );

  return (
    <>
      <Content>
        <FramedTitle text="What’s on" />
        <div className={styles.eventsContainer}>
          {upcoming.map((event) => (
            <EventSummary key={event.slug} {...event} />
          ))}
        </div>
        {past.length > 0 && (
          <>
            <Tile text="Past events" isActive={true} />
            <div className={styles.eventsContainer}>
              {past.map((event) => (
                <EventSummary key={event.slug} {...event} past />
              ))}
            </div>
          </>
        )}
      </Content>
    </>
  );
};

export default WhatsOn;
